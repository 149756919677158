import React, { useState } from "react"
import "../style/style.css"
import { useTransition, animated } from "react-spring"
import { OutboundLink } from "gatsby-plugin-google-gtag"
import Header from "../components/header"

function Racepage({ title, startlist, race }) {
  const [currentNumber, setCurrentNumber] = useState("1")
  const [datetimeLastPress, setDatetimeLastPress] = useState(Date.now())

  const handleNumPad = ev => {
    const addedNumber = ev.target.value.toString()
    const timeDifference = Date.now() - datetimeLastPress

    if (currentNumber.length < 3 && timeDifference < 4000) {
      setCurrentNumber(currentNumber.concat(addedNumber))
      setDatetimeLastPress(Date.now())
    } else {
      setCurrentNumber(addedNumber)
      setDatetimeLastPress(Date.now())
    }
  }

  const handleBackspace = () => {
    if (currentNumber.length > 0) {
      setCurrentNumber(currentNumber.slice(0, -1))
      setDatetimeLastPress(Date.now())
    }
  }
  const transition = useTransition(currentNumber, {
    from: { opacity: 0, filter: "blur(1rem)" },
    enter: { opacity: 1, filter: "blur(0rem)" },
    config: { tension: 150, clamp: true },
  })

  return (
    <div className="App">
      <div className="container">
        <Header title={title} race={race} />
        <div className="currentRider">
          {}

          {startlist.find(rider => rider.number === ` ${currentNumber}`) && (
            <>
              {transition((style, item) => {
                const rider = startlist.find(
                  rider => rider.number === ` ${currentNumber}`
                )
                const slug = `${rider.firstname}-${rider.lastname}`
                  .replace(/\s/g, "")
                  .toLowerCase()
                return (
                  <animated.div style={style}>
                    <h2>
                      {rider.firstname} {rider.lastname}
                    </h2>
                    <h3>{rider.team}</h3>
                    <OutboundLink
                      href={`https://procyclingstats.com/rider/${slug}`}
                    >
                      Check rider on PCS
                    </OutboundLink>
                  </animated.div>
                )
              })}
            </>
          )}
          {!startlist.find(rider => rider.number === ` ${currentNumber}`) && (
            <div>
              <img alt="riderIcon" src={require("../style/rider.png")} />
            </div>
          )}
        </div>
        <div
          className="currentNumber"
          style={{
            backgroundColor: `${
              startlist[currentNumber] ? "#cff1c5" : "#c5d4f1"
            }`,
          }}
        >
          {(currentNumber && `#${currentNumber}`) || `Enter a rider's number`}
          <span
            style={{ display: `${currentNumber ? "initial" : "none"}` }}
            className="clearNumberButton"
            onClick={() => setCurrentNumber("")}
          >
            clear
          </span>
        </div>
        <div className="numPad">
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="1"
          >
            1
          </button>
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="2"
          >
            2
          </button>
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="3"
          >
            3
          </button>
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="4"
          >
            4
          </button>
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="5"
          >
            5
          </button>
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="6"
          >
            6
          </button>
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="7"
          >
            7
          </button>
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="8"
          >
            8
          </button>
          <button
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="9"
          >
            9
          </button>
          <button
            style={{ gridColumn: `1 / span 2` }}
            onClick={ev => {
              handleNumPad(ev)
            }}
            value="0"
          >
            0
          </button>
          <button
            className="backspace"
            onClick={() => {
              handleBackspace()
            }}
          >
            <img alt="backspaceIcon" src={require("../style/backspace.png")} />
          </button>
        </div>
      </div>
    </div>
  )
}

export default Racepage
